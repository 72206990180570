<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">注册账户</h1>
				<p class="text-lg"></p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			
			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>

				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: '请输入邮箱！' }] },
						]"
						placeholder="邮箱"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入您的密码' }] },
						]"
						type="password"
						placeholder="密码"
					>
					</a-input>
				</a-form-item>
				<!-- <a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
					>
						 <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item> -->
				<a-form-item>
					<a-spin :spinning="registerLoading">
						<a-button type="primary" block html-type="submit" class="login-form-button">
							注册
						</a-button>
					</a-spin>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">已有账号？ <router-link to="/sign-in" class="font-bold text-dark">登录</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
import { notification } from 'ant-design-vue';
	export default ({
		data() {
			return {
				registerLoading:false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				var that=this
				this.registerLoading=true
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.$route.params.code
						this.$http({
							url:"https://api.wormholecloud.com/api/v1/register",
							method:'post',
							data:JSON.stringify({
								username:values.email,
								password:values.password,
								invite_code:this.$route.query.code==null||this.$route.query.code==""?'':this.$route.query.code,
							})
						}).then(res=>{
							this.registerLoading=false
							notification["success"]({
									message: '注册成功',
							});
							setTimeout(function(){
								that.$router.push('/sign-in')
							},1000)
						}).catch(err=>{
							this.registerLoading=false
							notification["error"]({
									message: '注册失败',
									description:
									err.response.data.message,
								});
						})
					}else{
						this.registerLoading=false
					}
				});
			},
		},
	})

</script>

<style lang="scss">
</style>